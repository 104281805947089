<template>
  <TransitionRoot as="template" :show="checkoutStore.isCartOpen">
    <Dialog
      ref="dialog"
      as="div"
      class="relative z-[999]"
      @close="closeWithDelay"
    >
      <div class="fixed inset-0 top-header-top-with-discount overflow-hidden">
        <div
          class="absolute inset-0 top-header-top-with-discount overflow-hidden"
        >
          <div
            class="pointer-events-none fixed bottom-0 right-0 top-header-top-with-discount flex max-w-full"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-250"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-250"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
              @after-enter="onAfterEnter"
            >
              <DialogPanel
                class="pointer-events-auto relative w-screen md:max-w-[494px]"
              >
                <div
                  class="flex h-full flex-col overflow-hidden bg-white shadow-xl"
                >
                  <div
                    class="border-b-neutral-black-300 flex items-center justify-between border-b px-4 py-6"
                  >
                    <DialogTitle class="ui-text-m uppercase text-gray-90">
                      {{ $t("yourCart") }}
                      {{ cartItemCount }}
                    </DialogTitle>
                    <UiButton
                      variant-type="icon"
                      variant="clear"
                      size="xs"
                      :aria-label="
                        $t('ariaLabelClose', { title: $t('yourShoppingBag') })
                      "
                      @click="checkoutStore.isCartOpen = false"
                    >
                      <UiIcon name="close" class="size-6 text-primary" />
                    </UiButton>
                  </div>
                  <template v-if="cartInfo">
                    <div
                      class="styled-scrollbars relative flex-1 overflow-auto px-4"
                    >
                      <template v-if="!isEmpty(cartItemsGrouped)">
                        <LazyCartProductCard
                          v-for="(product, index) in cartItemsGrouped"
                          :key="product.id"
                          :product="product"
                          :product-index="index"
                        />
                      </template>
                      <div v-else class="pt-6 text-center text-xl text-primary">
                        {{ $t("cartEmpty") }}
                      </div>
                    </div>
                    <div v-if="!isEmpty(cartItemsGrouped)">
                      <div
                        class="space-y-3.5 border-y border-y-off-white-30 p-4"
                      >
                        <div
                          v-for="info in cartInformation"
                          :key="info.label"
                          class="flex items-center justify-between text-sm font-light"
                        >
                          <div>{{ info.label }}</div>

                          <div :class="cn(info.extraClass)">
                            {{ info.value }}
                          </div>
                        </div>
                      </div>
                      <div class="px-4 py-7.5">
                        <UiButton
                          color="primary"
                          size="lg"
                          :to="
                            localePath(
                              `/checkout/${generalStore.cart?.unique_id || ''}`,
                            )
                          "
                          class="w-full capitalize"
                          data-e2e-cta="checkout"
                          @click="handleCheckoutClick"
                        >
                          {{ $t("checkout") }}
                        </UiButton>
                      </div>
                    </div>
                  </template>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { onClickOutside } from "@vueuse/core";
import { Cart } from "ecom-types";

const checkoutStore = useCheckoutStore();
const generalStore = useGeneralStore();
const generalInternalStore = useGeneralInternalStore();
const {
  closeCart,
  cartItemsGrouped,
  cartInfo,
  cartItemsQuantity,
  cartItems,
  hasOnlyDigitalItemsInCart,
} = useCart();
const { t, n } = useI18n();
const dialog = ref(null);

const isMounted = useMounted();
const { viewCartEvent, beginCheckoutEvent } = useEvents();

onClickOutside(dialog, () => {
  closeWithDelay();
});

watch(
  () => checkoutStore.isCartOpen,
  (val) => {
    generalInternalStore.systemOverlay = val;
  },
);

watchEffect(() => {
  if (isMounted.value && checkoutStore.isCartOpen) {
    viewCartEvent(cartInfo.value);
  }
});

const localePath = useLocalePathPolyfill();

const cartItemCount = computed(() => {
  return cartItemsQuantity.value ? `(${cartItemsQuantity.value})` : "";
});

function onAfterEnter() {
  setTimeout(() => {
    const inertEl = document.querySelector("[inert]");
    if (inertEl) {
      inertEl.removeAttribute("inert");
    }
  }, 0);
}

const cartInformation = computed(() => {
  const items = [
    {
      label: t("subtotal"),
      value: n(Number(cartInfo.value?.base_amount), "currency"),
      extraClass: "",
    },
  ];
  if (!hasOnlyDigitalItemsInCart.value) {
    items.push(
      {
        label: t("shipping"),
        value: t("free"),
        extraClass: "text-semantic-green-900 uppercase",
      },
      {
        label: t("taxes"),
        value: t("calculatedAtCheckout"),
        extraClass: "text-wood-900",
      },
    );
  }
  return items;
});

function closeWithDelay() {
  setTimeout(() => {
    checkoutStore.isCartOpen = false;
  }, 0);
}

function handleCheckoutClick() {
  closeCart();
  setBeginCheckoutEvents();
}
function setBeginCheckoutEvents() {
  beginCheckoutEvent({
    cartItems: cartItems.value,
    cartInfo: cartInfo.value as Cart,
  });
}
</script>
